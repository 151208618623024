import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import { ROUTE_DASHBOARD, ROUTE_DEALER, ROUTE_DEALS, ROUTE_INVOICES, ROUTE_STORE, ROUTE_STORES, ROUTE_TRACKING } from '@/router/routes';

export const NavigationMenuItems: NavigationMenuItemInterface[] = [
  
  {
    name: 'ROUTES.DEALERS',
    route: ROUTE_DEALER,
    icon: 'fas fa-user-alt',
    isActive: false,
    adminOnly: true,
    storeAdminOrAdminOnly: false
  },
  {
    name: 'ROUTES.STORES',
    route: ROUTE_STORES,
    icon: 'fas fa-store',
    isActive: false,
    adminOnly: true,
    storeAdminOrAdminOnly: false
  },
  {
    name: 'ROUTES.STORE',
    route: ROUTE_STORE,
    icon: 'fas fa-home',
    isActive: false,
    adminOnly: false,
    storeAdminOrAdminOnly: false,
    children: [
      // {
      //   name: 'ROUTES.DASHBOARD',
      //   route: ROUTE_DASHBOARD,
      //   icon: 'fas fa-th-large',
      //   isActive: false,
      //   adminOnly: false,
      // },
      {
        name: 'ROUTES.DEALS',
        route: ROUTE_DEALS,
        icon: 'fas fa-tags',
        isActive: false,
        adminOnly: false,
        storeAdminOrAdminOnly: false
      },
      {
        name: 'ROUTES.TRACKING',
        route: ROUTE_TRACKING,
        icon: 'fas fa-chart-line',
        isActive: false,
        adminOnly: false,
        storeAdminOrAdminOnly: false
      },
      {
        name: 'ROUTES.INVOICES',
        route: ROUTE_INVOICES,
        icon: 'fas fa-file-alt',
        isActive: false,
        adminOnly: false,
        storeAdminOrAdminOnly: true
      },
    ]
  },
];